import { Outlet, useRouteLoaderData } from '@remix-run/react';
import { useEffect } from 'react';
import { $routeId } from 'remix-routes';

import { EnumsPageName } from '@lp-lib/api-service-client/public';

import { PortalLink } from '../components/PortalContext';
import { PublicHomeProvider } from '../components/PublicHome/PublicHomeProvider';
import {
  REDIRECT_CUSTOM_TARGET_MAP,
  useUserRedirect,
} from '../hooks/useRedirection';
import { apiService } from '../services/api-service';

export async function clientLoader() {
  const pageName = EnumsPageName.PageNamePublicHome;
  const page = (await apiService.page.get(pageName)).data.page;
  return {
    pageName,
    page,
  };
}

export function usePublicHomeData() {
  const data = useRouteLoaderData<typeof clientLoader>(
    $routeId('routes/_shell-client.home._shell')
  );
  if (!data) throw new Error('No data');
  return data;
}

export function shouldRevalidate(): boolean {
  return false;
}

function Bootstrap() {
  const redirect = useUserRedirect();

  useEffect(() => {
    redirect([
      { kind: 'organizer', target: REDIRECT_CUSTOM_TARGET_MAP.noop },
      { kind: 'venueActivated', target: PortalLink.HostGamePack },
      {
        kind: 'guest',
        target: REDIRECT_CUSTOM_TARGET_MAP.noop,
        clearToken: true,
      },
      { kind: 'final', target: REDIRECT_CUSTOM_TARGET_MAP.noop },
    ]);
  }, [redirect]);

  return null;
}

export function Component(): JSX.Element {
  return (
    <PublicHomeProvider>
      <Outlet />
      <Bootstrap />
    </PublicHomeProvider>
  );
}
